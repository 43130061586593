import * as _callBind2 from "call-bind";
var _callBind = _callBind2;
try {
  if ("default" in _callBind2) _callBind = _callBind2.default;
} catch (e) {}
import * as _callBound2 from "call-bind/callBound";
var _callBound = _callBound2;
try {
  if ("default" in _callBound2) _callBound = _callBound2.default;
} catch (e) {}
import * as _getIntrinsic2 from "get-intrinsic";
var _getIntrinsic = _getIntrinsic2;
try {
  if ("default" in _getIntrinsic2) _getIntrinsic = _getIntrinsic2.default;
} catch (e) {}
var exports = {};
var callBind = _callBind;
var callBound = _callBound;
var GetIntrinsic = _getIntrinsic;
var $ArrayBuffer = GetIntrinsic("%ArrayBuffer%", true);
/** @type {undefined | ((receiver: ArrayBuffer) => number) | ((receiver: unknown) => never)} */
var $byteLength = callBound("ArrayBuffer.prototype.byteLength", true);
var $toString = callBound("Object.prototype.toString");

// in node 0.10, ArrayBuffers have no prototype methods, but have an own slot-checking `slice` method
var abSlice = !!$ArrayBuffer && !$byteLength && new $ArrayBuffer(0).slice;
var $abSlice = !!abSlice && callBind(abSlice);

/** @type {import('.')} */
exports = $byteLength || $abSlice ? function isArrayBuffer(obj) {
  if (!obj || typeof obj !== "object") {
    return false;
  }
  try {
    if ($byteLength) {
      // @ts-expect-error no idea why TS can't handle the overload
      $byteLength(obj);
    } else {
      // @ts-expect-error TS chooses not to type-narrow inside a closure
      $abSlice(obj, 0);
    }
    return true;
  } catch (e) {
    return false;
  }
} : $ArrayBuffer
// in node 0.8, ArrayBuffers have no prototype or own methods, but also no Symbol.toStringTag
? function isArrayBuffer(obj) {
  return $toString(obj) === "[object ArrayBuffer]";
} : function isArrayBuffer(obj) {
  // eslint-disable-line no-unused-vars
  return false;
};
export default exports;